import React from 'react'


export default props => {
    return (
        <div className="col-md-3 col-sm-6 col-12">
            <div className="info-box">
                <span className="info-box-icon bg-success"><i className={props.icon}></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">{props.title}</span>
                    <span className="info-box-number">{props.value}</span>
                </div>
            </div>
        </div>
    )
}