import MenuItem from './menuItem'
import { AuthContext } from '../contexts/auth';
import { useContext } from 'react';


export const Sidebar = () => {
    const { user } = useContext(AuthContext);

    let userLogged = JSON.parse(user);
    const isAdmin = userLogged?.metadata.rules.includes('EMPLOYEE_ADMIN')
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4 sidebar">

            <span className="brand-link text-center app-header">
                <span className="app-name">
                    <b>Star<span className='txt-beer'>Bella</span></b>
                </span>
            </span>

            <div className="sidebar">

                <div className="app-user-info">
                    <i className="fas fa-user-circle icon-user"></i>  <br></br>
                    <span>
                        {userLogged?.email}
                    </span>

                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <MenuItem path='/' label='Inicio' icon='th' />
                        {isAdmin ? <MenuItem path='/employees' label='Funcionários' icon='th' /> : false}
                        {isAdmin ? <MenuItem path='/report' label='Relatorio' icon='th' /> : false}                        
                    </ul>

                </nav>
            </div>
    
        </aside>
    )
};

