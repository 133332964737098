import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router';
import { bindActionCreators } from 'redux'
import { AuthContext } from '../contexts/auth';

export const Header  = () => {

  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const forceReload = () =>
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) =>
      Promise.all(registrations.map((r) => r.unregister())),
    )
    .then(() => window.location.reload())


    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <button type="button" className="nav-link btn btn-link" data-widget="pushmenu"><i className="fas fa-bars"></i></button>
          </li>

          <li className="navbar-nav ml-auto">
            <button onClick={forceReload} type="button" className="nav-link btn btn-link"><i className="fas fa-redo-alt"></i></button>
          </li>

          <li className="navbar-nav ml-auto">
            <button onClick={() => {
                    signOut()
                    navigate('/login')
                }} type="button" className="nav-link btn btn-link"><b>SAIR</b></button>
          </li>
        </ul>
      </nav>
    )
  
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Header)
