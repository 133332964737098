
// eslint-disable-next-line import/no-anonymous-default-export
export default props => {
    const { model } = props
    return (
        <div className="card card-widget widget-user">
            <div style={{ textAlign: 'center', color: 'grey' }}>
                <div>
                    <i style={{ fontSize: '50px', marginTop: '10px' }} className="fas fa-user-circle"></i>
                </div>
                <hr></hr>
                <p>{model.firstName}  {model.lastName}</p>
                <p>{model.email}</p>
                <p>{model.rules[0] === 'EMPLOYEE' ? 'Cabeleireira' : model.rules[0] === 'EMPLOYEE_ADMIN' ? 'Administrador' : 'Recepção'}</p>
                <p>Senha: {model.password}</p>
            </div>
        </div>
    )
}