
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { validate } from './validateConfirmPayment'
import { toasterError, toasterSuccess } from '../../components/toaster/customToaster'

import { formatCurrencyInputBR, formatReal, isNull } from '../../utils/util'
import { updateCommands } from '../repository/commandsRepository'
import { dateInYyyyMmDdHhMmSs } from '../../utils/dateUtils'
import { setForm, updateListCommand, findAllCommands } from '../actions/homeEmployeeReceptionAction'

import dayjs from 'dayjs'
import SelectField from '../../components/inputs/SelectField'
import ModalCustom from './CustomModal'
import CustomCurrencyField, { currencyConfig } from '../../components/inputs/CustomCurrencyField'
import IntlCurrencyInput from 'react-intl-currency-input'



class ConfirmPaymentButton extends Component {
    constructor(props) {
        super(props)
        this.create = this.create.bind(this)
        this.clickOpenModal = this.clickOpenModal.bind(this)
        this.changePix = this.changePix.bind(this)
        this.changeCreditCard = this.changeCreditCard.bind(this)
        this.changeDebit = this.changeDebit.bind(this)
        this.changeMoney = this.changeMoney.bind(this)

        this.state = {
            isLoadingModal: false,
            options: this.formPayments(),
            selectedPaymentMethod: null,
            pixValue: 0,
            creditCardValue: 0,
            debitCardValue: 0,
            moneyValue: 0,
            messageError: ''
        }
        this.props.setForm({});

    }

    formPayments() {
        return [
            {
                value: 'PIX',
                label: `Pix`
            },
            {
                value: 'CREDIT_CARD',
                label: `Cartão de crédito`
            },
            {
                value: 'DEBIT_CARD',
                label: `Cartão de debito`
            },
            {
                value: 'MONEY',
                label: `Dinheiro`
            },
        ]
    }

    validateValues(command) {
        const totalCommand = command.value;
        const { pixValue, creditCardValue, debitCardValue, moneyValue } = this.state
        const sumCustomValue = pixValue + creditCardValue + debitCardValue + moneyValue;

        return sumCustomValue != totalCommand;
    }

    getPayments() {
        const list = []
        const { pixValue, creditCardValue, debitCardValue, moneyValue } = this.state

        if (pixValue > 0) {
            list.push({'type': 'PIX', 'value': pixValue});
        }

        if (creditCardValue > 0) {
            list.push({'type': 'CREDIT_CARD', 'value': creditCardValue});
        }

        if (debitCardValue > 0) {
            list.push({'type': 'DEBIT_CARD', 'value': debitCardValue});
        }

        if (moneyValue > 0) {
            list.push({'type': 'MONEY', 'value': moneyValue});
        }

        return list;

    }

    async create() {
        const userSession = JSON.parse(localStorage.getItem("@AuthFirebase:user"));
        const { command, commands = [] } = this.props
        const { selectedPaymentMethod } = this.state

        if (this.validateValues(command)) {
            this.setState({messageError: `Os valores informados não batem com o valor da comanda de R$${command.value}`});
        } else {
            this.setState({messageError: ''});
            const data = {
                id: command.id,
                status: 'PAID',
                paymentInfo: {
                    paymentDate: dateInYyyyMmDdHhMmSs(new Date()),
                    paymentType: selectedPaymentMethod,
                    responsibleId: userSession.id,
                    responsibleName: `${userSession.metadata.firstName} ${userSession.metadata.lastName}`
                },
                payments: this.getPayments()

            }

            try {
                this.setState({ isLoadingModal: true })
                await updateCommands(data)
                this.props.findAllCommands()
                this.setState({ isLoadingModal: false })
                toasterSuccess()
            } catch (e) {
                this.setState({ isLoadingModal: false })
                toasterError()
            }
        }
    }


    changeFormPayment(formPaymentType) {
        this.setState({ selectedPaymentMethod: formPaymentType })
    }

    clickOpenModal() {
        const { command } = this.props
        this.props.setForm({});
        if (!isNull(command.paymentInfo)) {
            const data = { formOfPayment: command.paymentInfo.paymentType }
            this.setState({ selectedPaymentMethod: command.paymentInfo.paymentType })
            this.props.setForm(data);
        }
    }

    changePix(event, value, maskedValue) {
        this.setState({ pixValue: value });
    }

    changeCreditCard(event, value, maskedValue) {
        this.setState({ creditCardValue: value })
    }

    changeDebit(event, value, maskedValue) {
        this.setState({ debitCardValue: value })
    }

    changeMoney(event, value, maskedValue) {
        this.setState({ moneyValue: value })
    }


    render() {
        const { command, handleSubmit } = this.props
        const { isLoadingModal, messageError } = this.state;

        console.log("xxxxx",command?.payments?.filter(p => p.type === 'PIX')[0])

        return (
            <>

                <ModalCustom title={`Confirmar pagamento`}
                    openModalButtonLabel="Calcular Fator"
                    key={command.id}
                    id={command.id}
                    command={command}
                    clickButtonOpenModal={this.clickOpenModal}
                    clickConfirm={this.create}
                    isLoading={isLoadingModal}
                    label={command.status === 'PAID' ? 'Editar' : 'Confirmar pagamento'}
                    btnClass={command.status === 'PAID' ? "btn-outline-primary" : "btn-outline-success"}
                >

                    <form onSubmit={handleSubmit(this.create)}>
                        <div style={{ textAlign: 'left' }}>
                            <ul className="list-group">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <b>Cliente</b>
                                    <span className="rounded-pill">{command.customerName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <b>Valor</b>
                                    <span className="rounded-pill">{formatReal(command.value)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <b>Profissional</b>
                                    <span className="rounded-pill">{command.responsibleName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <b>Data </b>
                                    <span className="rounded-pill">{dayjs(command.createDateTime).format("DD/MM/YYYY HH:mm")}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <b>Serviço </b>
                                    <span className="rounded-pill">{command.serviceDescription}</span>
                                </li>
                            </ul>

                            <hr></hr>
                            {!isNull(messageError) && <div className="alert alert-danger" role="alert">
                                {messageError}
                            </div>}
                            <hr></hr>

                            <div className='row'>
                                <div className="form-group col-md-12">
                                    <label>PIX</label>
                                    <IntlCurrencyInput className='form-control' currency="BRL" config={currencyConfig}
                                        onChange={this.changePix} value={command?.payments?.filter(p => p.type === 'PIX')[0]?.value}/>

                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group col-md-12">
                                <label>Cartão de crédito</label>
                                    <IntlCurrencyInput className='form-control' currency="BRL" config={currencyConfig}
                                        onChange={this.changeCreditCard} value={command?.payments?.filter(p => p.type === 'CREDIT_CARD')[0]?.value}/>
                                </div>

                            </div>
                            <div className='row'>
                                <div className="form-group col-md-12">
                                <label>Cartão de debito</label>
                                    <IntlCurrencyInput className='form-control' currency="BRL" config={currencyConfig}
                                        onChange={this.changeDebit} value={command?.payments?.filter(p => p.type === 'DEBIT_CARD')[0]?.value}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group col-md-12">
                                <label>Dinheiro</label>
                                    <IntlCurrencyInput className='form-control' currency="BRL" config={currencyConfig}
                                        onChange={this.changeMoney} value={command?.payments?.filter(p => p.type === 'MONEY')[0]?.value}/>
                                </div>

                            </div>
                        </div>
                    </form>
                </ModalCustom>
            </>
        )
    }
}

ConfirmPaymentButton = reduxForm({
    form: 'confirmPaymentForm',
    validate: validate,
    destroyOnUnmount: false,
})(ConfirmPaymentButton)

const mapStateToProps = state => ({ commands: state.reception.commands })
const mapDispatchToProps = dispatch => bindActionCreators({ setForm, updateListCommand, findAllCommands }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentButton)