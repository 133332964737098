import Header from '../template/header';
import { Sidebar } from "../template/sidebar";
import Routes from './routes';

export const Home = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <div className='content-wrapper'>
        <div className='content'>
          <div className='container-fluid'>
            <Routes />
          </div>
        </div>
      </div>
    </>
  );
};