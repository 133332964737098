

import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { findAllCommands, advancedSearchCommands } from './actions/homeEmployeeReceptionAction'

import { CardCommandEmployeeReception } from './components/CardCommandEmployeeReception'
import Spinner from '../components/spinner/spinner'

import { findAllProfesional } from '../Professional/repository/professionalRepository'

import DatePicker from "react-multi-date-picker";
import ptBr from "react-date-object/locales/gregorian_pt_br"
import AppButton from '../components/buttons/AppButton'
import Select from 'react-select'
import { toasterError } from '../components/toaster/customToaster'
import { isNull } from '../utils/util'
import FloatButtonAddCommands from './components/FloatButtonAddCommands'
import FloatButtonRefresh from '../Reports/components/FloatButtonRefresh'


class HomeEmployeeReception extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            options: [],
            selectedProfessionals: [],
            selectedDate: [],
            paymentType: null,
            optionsStatus: [
                { value: 'AWAITING_PAYMENT', label: `Aguardando Pagamento` },
                { value: 'PAID', label: `Pago` }
            ]
        }
        this.search = this.search.bind(this)
        this.init = this.init.bind(this)

    }

    async componentDidMount() {
        this.init()
        this.findAllProfessional()
        this.setState({ isLoading: false })
    }

    async init() {
        this.setState({ isLoading: true })
        await this.props.findAllCommands()
        this.setState({ isLoading: false })
    }

    async findAllProfessional() {
        const professionals = await findAllProfesional()
        let options = []
        professionals.map(p => {
            options.push({ value: p.authId, label: `${p.firstName} ${p.lastName}`, id: p.id })
        })
        this.setState({ options: options })
    }

    renderCommands() {
        return <div className='row'>
            {
                this.props.commands.map(data => {
                    return <Fragment key={data.id}>
                        <CardCommandEmployeeReception command={data} />
                    </Fragment>
                })
            }
        </div>
    }

    changeDate(value) {
        this.setState({ selectedDate: value })
    }

    changeProfessional(values) {
        this.setState({ selectedProfessionals: values })
    }

    changePayment(value) {
        this.setState({ paymentType: value })
    }

    async search() {
        const { selectedProfessionals, selectedDate, paymentType } = this.state

        if (selectedProfessionals.length === 0 && selectedDate.length === 0 && isNull(paymentType)) {
            toasterError('Selecione a data ou o profissional')
        } else {
            let startDate = null
            let endDate = null
            let professionalIds = null

            if (selectedDate.length > 0) {
                const length = selectedDate.length
                if (length === 1) {
                    startDate = this.getDateByIndex(selectedDate, 0)
                } else if (length === 2) {
                    startDate = this.getDateByIndex(selectedDate, 0)
                    endDate = this.getDateByIndex(selectedDate, 1)
                }
            }

            if (selectedProfessionals.length > 0) {
                professionalIds = selectedProfessionals.map(data => data.value)
            }

            const data = {
                startDate: startDate,
                endDate: endDate,
                professionalIds: professionalIds,
                status: paymentType?.value
            }
            this.setState({ isLoading: true })
            await this.props.advancedSearchCommands(data)
            this.setState({ isLoading: false })

        }
    }

    getDateByIndex(selectedDate, index) {
        let month = selectedDate[index].month.number
        if (month.toString().length === 1) {
            month = '0'.concat(month)
        }
        let day = selectedDate[index].day
        if (day.toString().length === 1) {
            day = '0'.concat(day)
        }
        return `${selectedDate[index].year}-${month}-${day}`
    }


    render() {
        const { isLoading, options, optionsStatus } = this.state

        return (
            <div>
                <h3 style={{ textAlign: 'left' }}>Comandas</h3>
                <hr></hr>
                <div className='row'>
                    <div className='col-md-4'>
                        <label>Data</label>
                        <DatePicker onChange={(e) => this.changeDate(e)}
                            range
                            locale={ptBr}
                            format='DD/MM/YYYY'
                            containerStyle={{ width: "100%" }}
                            inputClass="custom-input" />
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Profissional</label>
                            <Select options={options} isMulti onChange={(value) => this.changeProfessional(value)} />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Status do pagamento</label>
                            <Select options={optionsStatus} onChange={(value) => this.changePayment(value)} />
                        </div>
                    </div>
                </div>
                <div className='row d-flex justify-content-end' style={{ marginRight: '1px' }}>
                    <AppButton click={this.search} label='Buscar' />
                </div>
                <hr></hr>
                {isLoading ? <div className='d-flex justify-content-center'>
                    <Spinner />
                </div> : this.renderCommands()}
                <FloatButtonRefresh refresh={this.init}/>

                <FloatButtonAddCommands isReception={true} professionals={options}/>

            </div>
        )
    }

}

const mapStateToProps = state => ({ commands: state.reception.commands })
const mapDispatchToProps = dispatch => bindActionCreators({ findAllCommands, advancedSearchCommands }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(HomeEmployeeReception)