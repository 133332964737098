export function dateInYyyyMmDdHhMmSs(date, dateDiveder = "-") {
  return (
    [
      date.getFullYear(),
      padTwoDigits(date.getMonth() + 1),
      padTwoDigits(date.getDate()),
    ].join(dateDiveder) +
    " " +
    [
      padTwoDigits(date.getHours()),
      padTwoDigits(date.getMinutes()),
    ].join(":")
  );
}


export function dateInYyyyMmDd(date, dateDiveder = "-") {
  return (
    [
      date.getFullYear(),
      padTwoDigits(date.getMonth() + 1),
      padTwoDigits(date.getDate()),
    ].join(dateDiveder)
  );
}

function padTwoDigits(num) {
  return num.toString().padStart(2, "0");
}


