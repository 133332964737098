import { Fragment } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Login } from "../Login";
import { Home } from "../Home";
import { PrivateRoutes } from ".";

export const AppRoutes = () => {
  return (
    <HashRouter>
      <Fragment>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Home />} />
          </Route> */}
          <Route path="/" element={<PrivateRoutes />}  />
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
        </Routes>
      </Fragment>
    </HashRouter>
  );
};