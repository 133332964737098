
import React, { Component } from 'react'

import CustomInput from '../components/inputs/CustomInput'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { validate } from './validate'
import Spinner from '../components/spinner/spinner'
import './login.css'
import AppButtonRounded from '../components/buttons/AppButtonRounded'

class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.login = this.login.bind(this)
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        this.installPWA()
    }


    async login(values) {
        this.setState({ isLoading: true })
        try {
            await this.props.handleLogin(values.email, values.password)
            this.setState({ isLoading: false })
        } catch (e) {
            this.setState({ isLoading: false })
        }

    }

    installPWA() {
        let myPrompt;
        const pwaAlert = document.querySelector('.pwa__alert');
        const btnPWA = document.querySelector('.pwa__alert__btn');

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            console.log('pronto para instalar', e);
            myPrompt = e;

            pwaAlert.style.display = 'block';
        });

        btnPWA.addEventListener('click', () => {
            pwaAlert.style.display = 'none';
            myPrompt.prompt();
            myPrompt.userChoice
                .then((choiceResult) => {
                    if (choiceResult === 'accepted') {
                        console.log('Usuário aceitou o prompt');
                    } else {
                        console.log('Usuário cancelou o prompt');
                    }
                });
        });
    }

    render() {
        const { handleSubmit } = this.props
        const { isLoading } = this.state

        return (
            <div className="login-page">
          
                <div className="login-box">
                    <div className="card-header text-center">
                        <span style={{ fontSize: '30px', color: '#ffff' }}><b>Star</b>Bella</span>
                        <div style={{display: 'none'}}  className="pwa__alert row">
                        <button type="button" className="pwa__alert__btn btn btn btn-success">Instalar APP</button>
                    </div>

                    </div>
                    <div className="card card-outline">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(this.login)}>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field name='email' component={CustomInput} isRequired
                                            label='Email' placeholder='informe seu email'
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field name='password' component={CustomInput} isRequired
                                            label='Senha' placeholder='informe sua senha' type='password'
                                        />
                                    </div>
                                </div>
                                <hr></hr>
                                <div>

                                    {
                                        isLoading ? <Spinner /> : <AppButtonRounded label='Entrar' />
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LoginPage = reduxForm({
    form: 'loginForm',
    validate: validate,
    destroyOnUnmount: false,
})(LoginPage)

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)