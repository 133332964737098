export function statusToDescription(status) {
    switch (status) {
        case 'AWAITING_PAYMENT':
            return 'Aguardando pagamento'
        case 'PAID':
            return 'Pago'
        default:
            return 'Aguardando pagamento'
    }
}
