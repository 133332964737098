
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { validate } from './validate'
import { toasterError } from '../../components/toaster/customToaster'

import ptBr from "react-date-object/locales/gregorian_pt_br"
import DatePicker from 'react-multi-date-picker'
import Select from 'react-select'
import './FloatSearch.css'
import AppButton from '../../components/buttons/AppButton'
import { isNull } from '../../utils/util'

class FloatButtonSearchEmployee extends Component {
    constructor(props) {
        super(props)
        this.filter = this.filter.bind(this)

        this.state = {
            isLoadingModal: false,
            selectedDate: [],
            paymentType: null,
            optionsStatus: [
                { value: null, label: `Todos` },
                { value: 'AWAITING_PAYMENT', label: `Aguardando Pagamento` },
                { value: 'PAID', label: `Pago` },
            ]
        }
    }

    filter() {
        const { selectedDate, paymentType } = this.state

        if (selectedDate.length === 0 && isNull(paymentType)) {
            toasterError('Preencha alguma informação para poder filtrar')
        } else {
            let startDate = null
            let endDate = null

            if (selectedDate.length > 0) {
                const length = selectedDate.length
                if (length === 1) {
                    startDate = this.getDateByIndex(selectedDate, 0)
                } else if (length === 2) {
                    startDate = this.getDateByIndex(selectedDate, 0)
                    endDate = this.getDateByIndex(selectedDate, 1)
                }
            }
            const userSession = JSON.parse(localStorage.getItem("@AuthFirebase:user"));

            const data = {
                startDate: startDate,
                endDate: endDate,
                professionalIds: [userSession.metadata.authId],
                status: paymentType?.value
            }
            var btnClose = document.getElementById('closeModal');
            btnClose.click();
            this.props.filter(data)            
        }
    }


    getDateByIndex(selectedDate, index) {
        let month = selectedDate[index].month.number
        if (month.toString().length === 1) {
            month = '0'.concat(month)
        }
        let day = selectedDate[index].day
        if (day.toString().length === 1) {
            day = '0'.concat(day)
        }
        return `${selectedDate[index].year}-${month}-${day}`
    }

    changeDate(value) {
        this.setState({ selectedDate: value })
    }


    changePayment(value) {
        this.setState({ paymentType: value })
    }


    render() {
        const { optionsStatus } = this.state

        return (
            <>
                <div className="float-search" data-toggle="modal" data-target="#exampleModalCenter">
                    <i className="fa fa-search my-float-search"></i>
                </div>

                <div className="modal fade" id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Pesquisar por</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='rows'>
                                    <div className='col-md-12'>
                                        <label>Data</label>
                                        <DatePicker onChange={(e) => this.changeDate(e)}
                                            range
                                            locale={ptBr}
                                            format='DD/MM/YYYY'
                                            containerStyle={{ width: "100%" }}
                                            inputClass="custom-input" />
                                    </div> 
                                    <p></p>
                                
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label>Status do pagamento</label>
                                            <Select options={optionsStatus} onChange={(value) => this.changePayment(value)} />
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='row d-flex justify-content-end' style={{ marginRight: '1px' }}>
                                    <div style={{marginRight: '10px'}}>
                                        <button id="closeModal" type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                    </div>
                            
                                    <AppButton click={() => this.filter()} label='Buscar' data-dismiss="modal" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

FloatButtonSearchEmployee = reduxForm({
    form: 'searchEmployeeForm',
    validate: validate,
    destroyOnUnmount: false,
})(FloatButtonSearchEmployee)

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FloatButtonSearchEmployee)