
import React from 'react'


import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import LoginPage from './login';

export const Login = () => {
  const { signIn, signed } = useContext(AuthContext);

  async function handleLogin(email, password) {
    await signIn(email, password);
  }

  if (!signed) {
    return <LoginPage handleLogin={handleLogin}/>
  } else {
    return <Navigate to="/" />;
  }
};
