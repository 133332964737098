import { isNull } from "../../utils/util"

export function validate (values) {
    const errors = {}
    if (isNull(values.customerName)) {
        errors.customerName = 'Campo obrigatório'
    }

    if (isNull(values.value)) {
        errors.value = 'Campo obrigatório'
    }

    if (isNull(values.serviceDescription)) {
        errors.serviceDescription = 'Campo obrigatório'
    }
    return errors
}