
import React, { Component } from 'react'
import ptBr from "react-date-object/locales/gregorian_pt_br"
import DatePicker from 'react-multi-date-picker'
import Select from 'react-select'
import AppButton from '../../components/buttons/AppButton'
import { toasterError } from '../../components/toaster/customToaster'
import { isNull } from '../../utils/util'

export default class FloatButtonFilterReport extends Component {
    constructor(props) {
        super(props)
        this.filter = this.filter.bind(this)

        this.state = {
            isLoading: true,
            isLoadingModal: false,
            options: [],
            selectedProfessionals: [],
            selectedDate: [],
            paymentType: null,
            optionsStatus: [
                { value: null, label: `Todos` },
                { value: 'AWAITING_PAYMENT', label: `Aguardando Pagamento` },
                { value: 'PAID', label: `Pago` },
            ]
        }
    }

    changeDate(value) {
        this.setState({ selectedDate: value })
    }

    changeProfessional(values) {
        this.setState({ selectedProfessionals: values })
    }

    changePayment(value) {
        this.setState({ paymentType: value })
    }


    filter() {
        const { selectedProfessionals, selectedDate, paymentType } = this.state

        if (selectedProfessionals.length === 0 && selectedDate.length === 0 && isNull(paymentType)) {
            toasterError('Preencha alguma informação para poder filtrar')
        } else {
            let startDate = null
            let endDate = null
            let professionalIds = null

            if (selectedDate.length > 0) {
                const length = selectedDate.length
                if (length === 1) {
                    startDate = this.getDateByIndex(selectedDate, 0)
                } else if (length === 2) {
                    startDate = this.getDateByIndex(selectedDate, 0)
                    endDate = this.getDateByIndex(selectedDate, 1)
                }
            }

            if (selectedProfessionals.length > 0) {
                professionalIds = selectedProfessionals.map(data => data.value)
            }

            const data = {
                startDate: startDate,
                endDate: endDate,
                professionalIds: professionalIds,
                status: paymentType?.value
            }
            var btnClose = document.getElementById('closeModal');
            btnClose.click();
            this.props.filter(data)
            
        }
    }

    getDateByIndex(selectedDate, index) {
        let month = selectedDate[index].month.number
        if (month.toString().length === 1) {
            month = '0'.concat(month)
        }
        let day = selectedDate[index].day
        if (day.toString().length === 1) {
            day = '0'.concat(day)
        }
        return `${selectedDate[index].year}-${month}-${day}`
    }


    render() {
        const { optionsStatus } = this.state

        return (
            <>
                <div className="float" data-toggle="modal" data-target="#exampleModalCenter">
                    <i className="fa fa-search my-float"></i>
                </div>

                <div className="modal fade" id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Pesquisar por</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='rows'>
                                    <div className='col-md-12'>
                                        <label>Data</label>
                                        <DatePicker onChange={(e) => this.changeDate(e)}
                                            range
                                            locale={ptBr}
                                            format='DD/MM/YYYY'
                                            containerStyle={{ width: "100%" }}
                                            inputClass="custom-input" />
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: '10px' }}>
                                        <div className='form-group'>
                                            <label>Profissional</label>
                                            <Select options={this.props.professionals} isMulti onChange={(value) => this.changeProfessional(value)} />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label>Status do pagamento</label>
                                            <Select options={optionsStatus} onChange={(value) => this.changePayment(value)} />
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='row d-flex justify-content-end' style={{ marginRight: '1px' }}>
                                    <div style={{marginRight: '10px'}}>
                                        <button id="closeModal" type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                    </div>
                            
                                    <AppButton click={() => this.filter()} label='Buscar' data-dismiss="modal" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
