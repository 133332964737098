import dayjs from 'dayjs'
import { formatReal } from '../../utils/util';
import { statusToDescription } from '../../utils/commandStatusUtil';
import ConfirmPaymentButton from './ConfirmPaymentButton';
import { DeleteCommandButton } from './DeleteCommandButton';

export const CardCommandEmployeeReception = (props) => {
    const command = props.command

    function getClassByType(status) {
        switch (status) {
            case 'AWAITING_PAYMENT':
                return 'badge badge-primary'
            case 'PAID':
                return 'badge badge-success'
            default:
                return 'badge badge-primary'
        }
    }

    function getPaymentDescription(paymentType) {
        switch (paymentType) {
            case 'PIX':
                return 'Pix';
            case 'CREDIT_CARD':
                return 'Cartão de crédito';
            case 'DEBIT_CARD':
                return 'Cartão de débito';
            case 'MONEY':
                return 'Dinheiro';
            default:
                return 'Tipo de pagamento desconhecido';
        }
    }

    function renderPayments(command) {
        return command.payments?.map(e => <span style={{margin: '4px'}} className="badge bg-primary">{getPaymentDescription(e.type)}</span>)
    }

    return (
        <div className="col-lg-4 col-12">
            <div style={{ textAlign: 'center' }} className="card">
                <div className="card-body">
                <span className="col-md-12 badge bg-secondary">{command.company === 'GOOOAiS5urYdOP3CUD2p' ? 'Cabelo' : 'Unha'}</span>

                    <p><b>Cliente: </b>{command.customerName}</p>
                    <p><b>Valor: </b>{formatReal(command.value)}</p>

                    <p><b>Profissional: </b>{command.responsibleName}</p>
                    <p><b>Data de criação: </b> {dayjs(command.createDateTime).format("DD/MM/YYYY HH:mm")}</p>
                    <p><b>Data do serviço: </b> {dayjs(command.createDate).format("DD/MM/YYYY")}</p>

                    <p>
                        <b>Serviço </b>
                        <span className="rounded-pill">{command.serviceDescription}</span>
                    </p>
                    <p>
                        <span className={`${getClassByType(command.status)} col-md-12`}>
                            {statusToDescription(command.status)}
                        </span>
                    </p>
                    
                    {renderPayments(command)}

                    <ConfirmPaymentButton command={command} />
                    <div style={{ marginTop: '10px' }}>
                        <DeleteCommandButton command={command} />
                    </div>

                </div>
            </div>
        </div>
    )
};