import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import { Provider } from 'react-redux'
import reducers from './reducers'
import thunk from "redux-thunk" 
import promise from 'redux-promise'
import multi from 'redux-multi'

const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
