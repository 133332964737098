
import React, { Component } from 'react'
import './FloatButtonRefresh.css'

export default class 
 extends Component {

    render() {
        return (
            <>
                <div onClick={this.props.refresh} className="float-refresh">
                    <i className="fas fa-redo-alt my-float-refresh"></i>
                </div>
            </>
        )
    }
}



