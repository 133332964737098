import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import {reducer as toastrReducer} from 'react-redux-toastr'

import EmployeeReducer from './Home/reducers/homeEmployeeReducer'
import EmployeeReceptionReducer from './Home/reducers/homeEmployeeReceptionReducer'


const rootReducer = combineReducers({
    form: formReducer,
    toastr: toastrReducer,
    employee: EmployeeReducer,
    reception: EmployeeReceptionReducer,
})

export default rootReducer