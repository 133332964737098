
import React, { Component } from 'react'


import { toasterError, toasterSuccess } from '../../components/toaster/customToaster'

import { formatReal } from '../../utils/util'
import { updateCommands } from '../repository/commandsRepository'
import { dateInYyyyMmDdHhMmSs } from '../../utils/dateUtils'

import ModalCustom from './CustomModal'


export class DeleteCommandButton extends Component {
    constructor(props) {
        super(props)
        this.deleteCommand = this.deleteCommand.bind(this)
        this.state = {
            isLoadingModal: false
        }
    }


    async deleteCommand() {
        const userSession = JSON.parse(localStorage.getItem("@AuthFirebase:user"));

        const { command } = this.props

        const data = {
            id: command.id,
            status: 'DELETED',
            deletedInfo: {
                deletedDate: dateInYyyyMmDdHhMmSs(new Date()),
                responsibleId: userSession.id,
                responsibleName: `${userSession.metadata.firstName} ${userSession.metadata.lastName}`
            }
        }

        try {
            this.setState({ isLoadingModal: true })
            await updateCommands(data)
            toasterSuccess()
            window.location.reload()
        } catch (e) {
            this.setState({ isLoadingModal: false })
            toasterError()
        }
   

    }


    changeFormPayment(formPaymentType) {
        this.setState({ selectedPaymentMethod: formPaymentType })
    }


    render() {
        const { command } = this.props
        const { isLoadingModal } = this.state
        return (
            <>

                <ModalCustom title={`Excluir comanda`}
                    key={command.id}
                    id={`${command.id}delete`}
                    command={command}
                    clickConfirm={this.deleteCommand}
                    isLoading={isLoadingModal}
                    label='Excluir comanda'
                    btnClass='btn-outline-danger'
                >

                  <p>Deseja excluir a comanda do(a) cliente {command.customerName} no valor de {formatReal(command.value)}?</p>
                
                </ModalCustom>
            </>
        )
    }
}