export function isNull(value) {
    return value === null || value === undefined || value === '';
}

export function formatReal(number) {

    if (Number.isNaN(number) || !number) return `R$ 0,00`;

    if (typeof number === "string") { // n1
        number = Number(number);
    }

    let res;
    const config = { style: 'currency', currency: 'BRL' };

    res = number.toLocaleString('pt-BR', config)

    const needComma = number => number <= 1000;
    if (needComma(number)) {
        res = res.toString().replace(".", ",");
    }
    return res;
}

export function formatCurrencyInputBR(value) {
    return value.toString()
        .replace('R$', '')
        .replace('.', '')
        .replace(',', '.')
}

export function formatDateToUS(date) {
    const split = date.split('/')
    return [split[2], split[1], split[0]].join('-');
}

export function formatDateToBR(date) {
    const split = date.split('-')
    return [split[2], split[1], split[0]].join('/');
}

export function getDateByIndex(selectedDate) {
    let month = selectedDate.month.number
    if (month.toString().length === 1) {
        month = '0'.concat(month)
    }
    let day = selectedDate.day
    if (day.toString().length === 1) {
        day = '0'.concat(day)
    }
    return `${selectedDate.year}-${month}-${day}`
}
