import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { findAllCommandsUserLogged, advancedSearchUserLogged } from './actions/homeEmployeeAction'

import FloatButtonAddCommands from './components/FloatButtonAddCommands'
import { CardCommandEmployee } from './components/CardCommandEmployee'
import FloatButtonSearchEmployee from '../Professional/components/FloatButtonSearchEmployee'
import Spinner from '../components/spinner/spinner'

class HomeEmployee extends Component {

    constructor(props) {
        super(props)
        this.filter = this.filter.bind(this)

        this.state = {
            isLoading: true,
        }
    }

    async componentDidMount() {
        await this.props.findAllCommandsUserLogged()
        this.setState({isLoading: false})
    }

    async filter(data) {
        this.setState({isLoading: true})
        await this.props.advancedSearchUserLogged(data)
        this.setState({isLoading: false})
    }

    renderCommands() {
        return <div className='row'>
            {
                this.props.commands.map(data => {
                    return <Fragment key={data.id}>
                        <CardCommandEmployee command={data} />
                    </Fragment>
                })
            }
        </div>
    }

    render() {
        const { isLoading } = this.state

        return (
            <div>
                <h3 style={{ textAlign: 'left' }}>Minhas comandas</h3>
                <p>Versão 1.0.0</p>
                <hr></hr>
                {isLoading ? <Spinner/> : this.renderCommands()}
                <div className='row'>
                    <FloatButtonSearchEmployee filter={this.filter} />
                </div>
                <div className='row'>
                    <FloatButtonAddCommands />
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({ commands: state.employee.commands })
const mapDispatchToProps = dispatch => bindActionCreators({ findAllCommandsUserLogged, advancedSearchUserLogged }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(HomeEmployee)