
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { validate } from './validate'
import { toasterError, toasterSuccess } from '../../components/toaster/customToaster'

import { formatCurrencyInputBR, formatDateBRtoISO, getDateByIndex, isNull } from '../../utils/util'
import { createCommands } from '../repository/commandsRepository'
import { CommandsEnum } from '../enums/commandsEnum'
import { dateInYyyyMmDd, dateInYyyyMmDdHhMmSs } from '../../utils/dateUtils'
import { setNewCommandInList } from '../actions/homeEmployeeAction'

import Spinner from '../../components/spinner/spinner'
import CustomInput from '../../components/inputs/CustomInput'
import CustomCurrencyField from '../../components/inputs/CustomCurrencyField'
import CustomTextAreaInput from '../../components/inputs/CustomTextAreaInput'

import '../homeEmployee.css'
import AppButtonRounded from '../../components/buttons/AppButtonRounded'
import Select from 'react-select'
import DatePicker from 'react-multi-date-picker'
import ptBr from "react-date-object/locales/gregorian_pt_br"


class FloatButtonAddCommand extends Component {
    constructor(props) {
        super(props)
        this.create = this.create.bind(this)

        this.state = {
            isLoadingModal: false,
            selectedProfessional: undefined,
            company: '',
            selectedDate: null,
        }
    }

    async create(values) {

        if (isNull(this.state.company) && this.props.isReception) {
            alert("Informe uma empresa!")
            throw new Error("Empresa não informada");
        }
        const { selectedProfessional } = this.state
        const userSession = JSON.parse(localStorage.getItem("@AuthFirebase:user"));
        const hasSelectedProfessional = !isNull(selectedProfessional)
        const data = {
            customerName: values.customerName,
            serviceDescription: values.serviceDescription,
            value: parseFloat(formatCurrencyInputBR(values.value)),
            responsibleName: hasSelectedProfessional ? selectedProfessional.label : `${userSession.metadata.firstName} ${userSession.metadata.lastName}`,
            responsibleId: hasSelectedProfessional ? selectedProfessional.value : userSession.id,
            responsibleAuthId: hasSelectedProfessional ? selectedProfessional.value : userSession.metadata.authId,
            status: CommandsEnum.AWAITING_PAYMENT,
            createDateTime: dateInYyyyMmDdHhMmSs(new Date()),
            createDate: dateInYyyyMmDd(new Date()),
            responsibleCreation: hasSelectedProfessional ? 'RECEPTION' : 'EMPLOYEE'
        }

        if (this.props.isReception) {
            data.company = this.state.company
            if (!isNull(this.state.selectedDate)) {
                data.createDate = getDateByIndex(this.state.selectedDate);
            }
        } else if (userSession?.metadata?.companies != null) {
            data.company = userSession.metadata.companies[0]
        }
        try {
            this.setState({ isLoadingModal: true })
            await createCommands(data)
            this.setState({ isLoadingModal: false })
            this.props.initialize('addCommandForm', {})
            this.props.setNewCommandInList(this.props.commands, data)
            toasterSuccess()
        } catch (e) {
            console.log(e)
            this.setState({ isLoadingModal: false })
            toasterError()
        }

    }

    changeProfessional(values) {
        this.setState({ selectedProfessional: values })
    }

    changeCompany(company) {
        this.setState({ company: company })
    }

    changeDate(value) {
        this.setState({ selectedDate: value })
    }

    render() {
        const { handleSubmit, professionals, isReception } = this.props
        const { isLoadingModal } = this.state

        return (
            <>
                <div className="float" data-toggle="modal" data-target="#modalAddCommand">
                    <i className="fa fa-plus my-float"></i>
                </div>

                <div className="modal fade" id="modalAddCommand" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Adicionar comanda</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit(this.create)}>
                                    {
                                        isReception ? <div className='form-group'>
                                            <label>Empresa <b style={{ color: 'red' }}>*</b></label>
                                            <div>
                                                <button style={this.state.company === 'GOOOAiS5urYdOP3CUD2p' ? { background: 'black', color: '#ffff' } : {}}
                                                    onClick={() => this.changeCompany('GOOOAiS5urYdOP3CUD2p')}
                                                    type="button"
                                                    className="btn btn-outline-dark col-md-6">Cabelo</button>

                                                <button onClick={() => this.changeCompany('DDb3UQWWoJDymZ5JxOKB')}
                                                    style={this.state.company === 'DDb3UQWWoJDymZ5JxOKB' ? { background: 'black', color: '#ffff' } : {}}
                                                    type="button"
                                                    className="btn btn-outline-dark col-md-6">Unha</button>
                                            </div>
                                        </div> : false
                                    }

                                    <div className="form-group">
                                        <Field name='customerName' component={CustomInput} isRequired
                                            label='Nome cliente' placeholder='informe o nome do(a) cliente'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Field name='value' component={CustomCurrencyField} isRequired
                                            label='Valor' placeholder='informe o valor do serviço realizado'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Field name='serviceDescription' component={CustomTextAreaInput} isRequired
                                            label='Serviço realizado' placeholder='informe o serviço realizado'
                                        />
                                    </div>
                                    {
                                        isReception ? <div className='form-group'>
                                            <label>Profissional</label>
                                            <Select options={professionals} onChange={(value) => this.changeProfessional(value)} />
                                        </div> : false
                                    }

                                    {
                                        isReception ? <>
                                            <label style={{marginTop: '10px'}}>Data</label>
                                            <DatePicker onChange={(e) => this.changeDate(e)}
                                                
                                                locale={ptBr}
                                                format='DD/MM/YYYY'
                                                containerStyle={{ width: "100%" }}
                                                inputClass="custom-input" />
                                        </> : false
                                    }

                                    <div className="modal-footer">
                                        {
                                            isLoadingModal ? <Spinner /> : <>
                                                <AppButtonRounded type='button' color='gray' label='Cancelar' dismiss="modal" />
                                                <AppButtonRounded />
                                            </>
                                        }
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

FloatButtonAddCommand = reduxForm({
    form: 'addCommandForm',
    validate: validate,
    destroyOnUnmount: false,
})(FloatButtonAddCommand)

const mapStateToProps = state => ({ commands: state.employee.commands })
const mapDispatchToProps = dispatch => bindActionCreators({ setNewCommandInList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FloatButtonAddCommand)