import { useContext } from "react";
import { AuthContext } from "../contexts/auth";
import HomeReception from "./homeReception";
import HomeEmployee from "./HomeEmployee";

export const Home = () => {
    const { user } = useContext(AuthContext);
    const userLogged = JSON.parse(user);
    const isReception = userLogged?.metadata?.rules.includes('EMPLOYEE_RECEPTION')

    if (isReception) {
        return  <HomeReception/>
    } 
    return  <HomeEmployee/>
};