import { useState, createContext, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../configs/config"
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { toasterError } from "../components/toaster/customToaster";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

  const auth = getAuth(app);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loadStorageData = () => {
      const storageUser = localStorage.getItem("@AuthFirebase:user");
      const storageToken = localStorage.getItem("@AuthFirebase:token");
      if (storageToken && storageUser) {
        setUser(storageUser);
      }
    };
    loadStorageData();
  });

  async function signIn(email, password) {
    await signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
        var user = userCredential.user;
        const token = user.accessToken;
        var infoData = await getInfoData(user.uid)
        const data = {
          'email':user.email,
          'id': user.uid,
          'metadata': infoData
        }
        setUser(data);
        localStorage.setItem("@AuthFirebase:token", token);
        localStorage.setItem("@AuthFirebase:user", JSON.stringify(data));
    })
    .catch((error) => {
        console.log("error", error)
        toasterError('Usuario ou senha inválido');
      });
  }


  async function getInfoData(authId) {
    const db = getFirestore(app);
    const usersRef = collection(db, "users");
    const querySnapshot = await getDocs(query(usersRef, where("authId", "==", authId)));
    let infoData = null
    querySnapshot.forEach((doc) => {
        infoData = doc.data()
    });
    return infoData
}

  function signOut() {
    localStorage.clear();
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};