
import React, { Component } from 'react'
import Spinner from '../../components/spinner/spinner'

export default class ModalCustom extends Component {

    render() {
        const { command } = this.props
        return (
            <div>
                <button onClick={this.props.clickButtonOpenModal != null ? () => this.props.clickButtonOpenModal() : null} 
                type="button" className={`btn ${this.props.btnClass} col-md-12`}
                data-toggle="modal" data-target={`#modal${this.props.id}`}>
                    {
                        this.props.label
                    }
                </button>

                <div className="modal fade" id={`modal${this.props.id}`}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ color: 'black' }}>
                                    {this.props.title}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.props.children}
                            </div>
                            <div className="modal-footer">
                                {
                                    this.props.isLoading ? <Spinner /> : <>
                                        <button type="button" id="closeModalCustom" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                        <button onClick={() => this.props.clickConfirm()} type="button" className="btn btn-success">Confirmar</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}