import dayjs from 'dayjs'
import { formatReal } from '../../utils/util';
import { statusToDescription } from '../../utils/commandStatusUtil';

export const CardCommandEmployee = (props) => {
    const command = props.command

    function getClassByType(status) {
        switch (status) {
            case 'AWAITING_PAYMENT':
                return 'badge badge-primary'
            case 'PAID':
                return 'badge badge-success'
            default:
                return 'badge badge-primary'
        }
    }

    return (
        <div className="col-lg-4 col-12">
            <div style={{ textAlign: 'center' }} className="card">
                <div className="card-body">
                    <p>{command.customerName}</p>
                    <p>{formatReal(command.value || 0)}</p>
                    <p>{command.serviceDescription}</p>
                    <p>
                        <span className={`${getClassByType(command.status)} col-md-12`}>
                            {statusToDescription(command.status)}
                        </span>
                    </p>
                    {command.status === 'PAID' && <p>Pgto. confirmado por: {command?.paymentInfo?.responsibleName}</p>}
                    <p>
                        {dayjs(command.createDateTime).format("DD/MM/YYYY HH:mm")}
                    </p>
                </div>
            </div>
        </div>
    )
};