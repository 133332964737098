
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { validate } from './validate'
import { toasterError, toasterSuccess } from '../../components/toaster/customToaster'


import Spinner from '../../components/spinner/spinner'
import AppButton from '../../components/buttons/AppButton'
import CustomInput from '../../components/inputs/CustomInput'

import { createEmployee } from '../repository/professionalRepository'
import AppButtonRounded from '../../components/buttons/AppButtonRounded'


class FloatButtonAddEmployee extends Component {
    constructor(props) {
        super(props)
        this.create = this.create.bind(this)

        this.state = {
            isLoadingModal: false
        }
    }

    async create(values) {
        try {
            this.setState({ isLoadingModal: true })
            values.companies = [this.props.company]
            await createEmployee(values)
            this.props.initialize('addEmployeeForm', {})
            this.setState({ isLoadingModal: false })
            toasterSuccess()
        } catch (_) {
            this.setState({ isLoadingModal: false })
            toasterError()
        }
    }

    render() {
        const { handleSubmit } = this.props
        const { isLoadingModal } = this.state

        return (
            <>
                <div className="float" data-toggle="modal" data-target="#exampleModalCenter">
                    <i className="fa fa-plus my-float"></i>
                </div>

                <div className="modal fade" id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Adicionar funcionário</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit(this.create)}>
                                    <div className="form-group">
                                        <Field name='firstName' component={CustomInput} isRequired
                                            label='Nome' placeholder='informe o primeiro nome'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Field name='lastName' component={CustomInput} isRequired
                                            label='Sobrenome' placeholder='informe o sobrenome'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Field name='email' component={CustomInput} isRequired
                                            label='E-mail' placeholder='informe o email'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Field name='password' component={CustomInput} isRequired
                                            label='Senha' type='password' placeholder='informe a senha'
                                        />
                                    </div>

                                    <div className="modal-footer">
                                        {/* {
                                            isLoadingModal ? <Spinner /> : <>
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                                <AppButton type='submit' />
                                            </>
                                        } */}

                                        {
                                            isLoadingModal ? <Spinner /> : <>
                                                <AppButtonRounded type='button' color='gray' label='Cancelar' dismiss="modal" />
                                                <AppButtonRounded type='submit'/>
                                            </>
                                        }
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

FloatButtonAddEmployee = reduxForm({
    form: 'addEmployeeForm',
    validate: validate,
    destroyOnUnmount: false,
})(FloatButtonAddEmployee)

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FloatButtonAddEmployee)