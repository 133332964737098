import { useContext } from "react";
import { AuthContext } from "../contexts/auth";
import { Home } from "../Home/Home";
import { Professionals } from "./professionals";

export const Employee = () => {
    const { user } = useContext(AuthContext);
    const userLogged = JSON.parse(user);
    const isAdmin = userLogged?.metadata?.rules.includes('EMPLOYEE_ADMIN')

    if (isAdmin) {
        return  <Professionals/>
    } 
    return <Home/>
};