import React from 'react'

import { Route, Routes } from 'react-router'
import { Home } from './Home'
import { Employee } from '../Professional'
import { ReportPage } from '../Reports'

export default props => (
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/employees" element={<Employee/>}/>
        <Route path="/report" element={<ReportPage/>}/>
    </Routes>
)

