import { isNull } from "../../utils/util"

export function validate (values) {
    const errors = {}
    if (isNull(values.firstName)) {
        errors.firstName = 'Campo obrigatório'
    }

    if (isNull(values.lastName)) {
        errors.lastName = 'Campo obrigatório'
    }

    if (isNull(values.email)) {
        errors.email = 'Campo obrigatório'
    }

    if (isNull(values.password)) {
        errors.password = 'Campo obrigatório'
    }

    return errors
}