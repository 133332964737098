import { useContext } from "react";
import { AuthContext } from "../contexts/auth";
import { Home } from "../Home/Home";
import Reports from "./reports";

export const ReportPage = () => {
    const { user } = useContext(AuthContext);
    const userLogged = JSON.parse(user);
    const isAdmin = userLogged?.metadata?.rules.includes('EMPLOYEE_ADMIN')

    if (isAdmin) {
        return  <Reports userLogged={userLogged} />
    } 
    return <Home/>
};