import { app } from "../../configs/config";
import { collection, query, getDocs, addDoc, getFirestore, where} from "firebase/firestore";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

export async function findAllProfesional(data) {
    const db = getFirestore(app);
    try {
        const ref = collection(db, "users");
        const querySnapshot = await getDocs(query(ref));

        let collect = []
        querySnapshot.forEach((doc) => {
            const data = {
                ...doc.data(),
                id: doc.id
            }
            collect.push(data)
        });
        return collect
    } catch (e) {
        console.error("Error search document: ", e);
        return []
    }
}

export async function findAllProfesionalByCompany(company) {
    const db = getFirestore(app);
    try {
        const ref = collection(db, "users");
        const q = query(ref, where("companies", "array-contains", company));
        const querySnapshot = await getDocs(q);

        let collect = []
        querySnapshot.forEach((doc) => {
            const data = {
                ...doc.data(),
                id: doc.id
            }
            collect.push(data)
        });
        return collect
    } catch (e) {
        console.error("Error search document: ", e);
        return []
    }
}

export async function createEmployee(data) {
    const auth = getAuth(app);
    return await createUserWithEmailAndPassword(auth, data.email, data.password)
        .then((userCredential) => {
            const user = userCredential.user;
            createCollectionProfile(data, user.uid)
        })
        .catch((error) => {
            console.log("error", error)
            throw error
        });
}

export async function createCollectionProfile(data, uid) {
    try {
        const db = getFirestore(app);

        const docRef = await addDoc(collection(db, "users"), {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            authId: uid,
            companies: data.companies,
            rules: ['EMPLOYEE']

        });
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }

}