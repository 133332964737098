import React, { Component, Fragment } from 'react'
import CardSummary from '../Home/components/CardSummary'
import { formatReal, isNull } from '../utils/util'
import { CardCommandEmployeeReception } from '../Home/components/CardCommandEmployeeReception'
import { advancedSearch, updateCommands } from '../Home/repository/commandsRepository'
import dayjs from 'dayjs'
import FloatButtonFilterReport from './components/FloatButtonFilterReport'
import { findAllProfesionalByCompany } from '../Professional/repository/professionalRepository'
import Spinner from '../components/spinner/spinner'
import FormPayment from './components/FormPayment'
import FloatButtonRefresh from './components/FloatButtonRefresh'

export default class Reports extends Component {

    constructor(props) {
        super(props)
        this.renderCommands = this.renderCommands.bind(this)
        this.filter = this.filter.bind(this)
        this.init = this.init.bind(this)
        this.getCalculateByType = this.getCalculateByType.bind(this)

        this.state = {
            isLoading: true,
            commands: [],
            totalValue: 0,
            totalValuePending: 0,
            totalCustomers: 0,
            professionals: [],
        }
    }

    componentDidMount() {
        this.init()
        this.findAllProfessional()
    }

    init() {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;


        const [firstDate, lastDate] = this.getFirstAndLastDateOfMonth(year, month);

        const filter = {
            startDate: dayjs(new Date().toISOString()).format("YYYY-MM-DD"),
            endDate:   dayjs(new Date().toISOString()).format("YYYY-MM-DD"),
            company: this.props.userLogged?.metadata?.companies[0]
        }

        this.getCommands(filter)
    }

    async getCommands(filter) {
        this.setState({ isLoading: true })
        const data = await advancedSearch(filter);
        console.log(data)

        // for (var x=0; x < data.length; x++) {
        //     var cmd = data[x]
        //     cmd.company = 'GOOOAiS5urYdOP3CUD2p'
        //     updateCommands(cmd)
        // }
       
  
        const total = data.filter(d => d.status === 'PAID').reduce((acc, item) => acc + item.value, 0);
        const totalPending = data.filter(d => d.status !== 'PAID').reduce((acc, item) => acc + item.value, 0);
        this.setState({
            commands: data,
            totalValue: total,
            totalCustomers: data.length,
            isLoading: false,
            totalValuePending: totalPending,
            endDate: dayjs(filter.endDate).format("DD/MM/YYYY"),
            startDate: dayjs(filter.startDate).format("DD/MM/YYYY")
        })
    }

    getCalculateByType(type) {
        const { commands } = this.state
        const total = commands.filter(d => d.status === 'PAID' && d.paymentInfo.paymentType === type)
            .reduce((acc, item) => acc + item.value, 0);
        
            if (total === 0) {
                return this.sumPaymentsByType(commands, type)
            }
        return formatReal(total || 0)
    }

    sumPaymentsByType(items, type) {
        // Filtra os itens com status "PAID"
        const paidItems = items.filter(item => item.status === 'PAID');
      
        // Inicializa um objeto para armazenar as somas
        const paymentSums = {};
      
        // Itera sobre os itens filtrados
        paidItems.forEach(item => {
          item.payments.forEach(payment => {
            // Se o tipo de pagamento já existe no objeto, soma o valor
            // Caso contrário, inicializa com o valor atual
            if (paymentSums[payment.type]) {
              paymentSums[payment.type] += payment.value;
            } else {
              paymentSums[payment.type] = payment.value;
            }
          });
        });      
        return formatReal(paymentSums[type] || 0);
      }

    getCalculateQtd(type) {
        const { commands } = this.state
        const length = commands.filter(d => d.status === 'PAID' && d.paymentInfo.paymentType === type).length;

        if (length === 0) {
            const paids = commands.filter(d => d.status === 'PAID' && d.payments.filter(p => p.type === type).length > 0);
            return paids.length;
        }
        return length
    }

    async findAllProfessional() {
        const professionals = await findAllProfesionalByCompany(this.props.userLogged?.metadata?.companies[0])
        let options = []
        professionals.map(p => {
            options.push({ value: p.authId, label: `${p.firstName} ${p.lastName}` })
        })
        this.setState({ professionals: options })
    }

    getFirstAndLastDateOfMonth(year, month) {
        const firstDate = new Date(year, month - 1, 1);
        const lastDate = new Date(year, month, 0);
        return [firstDate, lastDate];
    }

    renderCommands() {
        const { commands } = this.state

        return <div className='row'>
            {
                commands.map(data => {
                    return <Fragment key={data.id}>
                        <CardCommandEmployeeReception command={data} />
                    </Fragment>
                })
            }
        </div>
    }

    filter(filter) {
        if (isNull(filter.startDate)) {
            const year = new Date().getFullYear();
            const month = new Date().getMonth() + 1;
            const [firstDate, lastDate] = this.getFirstAndLastDateOfMonth(year, month);
            filter.startDate = dayjs(firstDate.toISOString()).format("YYYY-MM-DD")
            filter.endDate = dayjs(lastDate.toISOString()).format("YYYY-MM-DD")
        }
        filter.company = this.props.userLogged?.metadata?.companies[0]
        this.getCommands(filter)
    }

    render() {
        const {
            isLoading,
            totalValue,
            totalCustomers,
            professionals,
            totalValuePending,
            startDate,
            endDate,
            commands
        } = this.state

        return (
            <div>
                <div className='row'>
                    <CardSummary
                        isLoading={isLoading}
                        title={formatReal(totalValue || 0)}
                        subtitle='Valor total'
                        className='bg-success'
                        icon='fa-dollar-sign' />

                    <CardSummary
                        title={formatReal(totalValuePending || 0)}
                        subtitle='Valor pendente'
                        className='bg-warning'
                        icon='fa-dollar-sign' />

                    <CardSummary
                        isLoading={isLoading}
                        title={totalCustomers || 0}
                        subtitle='Total de clientes'
                        className='bg-info'
                        icon='fa-users' />

                </div>

                <hr></hr>
                <div className='row'>
                    <div className="col-md-3 col-sm-6 col-12">
                        <div className="info-box">
                            <span className="info-box-icon bg-success">PIX</span>
                            <div className="info-box-content">
                                <span className="info-box-text">Pix - Quantidade: {this.getCalculateQtd('PIX')}</span>
                                <span className="info-box-number">{this.getCalculateByType('PIX')}</span>
                            </div>
                        </div>
                    </div>
                    <FormPayment icon='fas fa-credit-card' title={`Crédito - Quantidade: ${this.getCalculateQtd('CREDIT_CARD')}`} value={this.getCalculateByType('CREDIT_CARD')} />
                    <FormPayment icon='fas fa-credit-card' title={`Débito - Quantidade: ${this.getCalculateQtd('DEBIT_CARD')}`} value={this.getCalculateByType('DEBIT_CARD')} />
                    <FormPayment icon='fas fa-money-bill-wave' title={`Dinheiro -  Quantidade: ${this.getCalculateQtd('MONEY')}`} value={this.getCalculateByType('MONEY')} />

                </div>
                <hr></hr>
                <h5 style={{ textAlign: 'center' }}>Movimentações</h5>
                <p style={{ textAlign: 'center' }}> {startDate} á {endDate}</p>

                {isLoading ? <div className='d-flex justify-content-center'><Spinner /></div> : this.renderCommands()}
                {isLoading ? '' : commands.length === 0 ? <span className='d-flex justify-content-center'>Nenhum resultado encontrado.</span> : ''}
                <FloatButtonRefresh refresh={this.init}/>
                <FloatButtonFilterReport filter={this.filter} professionals={professionals} />

            </div>
        )
    }
}