
import React, { useContext, useEffect, useState } from 'react'

import CardEmployee from './components/EmployeeCard'

import { findAllProfesionalByCompany } from './repository/professionalRepository'
import FloatButtonAddEmployee from './components/FloatButtonAddEmployee'
import { AuthContext } from '../contexts/auth';



export const Professionals = () => {
    const { user } = useContext(AuthContext);
    const [employess, setEmployess] = useState([]);
    const [company, setCompany] = useState();

    const loadEmployees = async () => {
        try {
            let userLogged = JSON.parse(user);
            setCompany(userLogged.metadata.companies[0])
            const employeesData = await findAllProfesionalByCompany(userLogged.metadata.companies[0]);
            setEmployess(employeesData);
        } catch (error) {
            console.error("Error loading employees:", error);
        }
    };

    useEffect(() => {
        loadEmployees();
    },[]);

    
    return (
        <div>
            <h3>Funcionários</h3>
            <hr></hr>

            <div className='row'>
                {employess?.map(emp => {
                    return <div className='col-md-4' key={emp.id}>
                        <CardEmployee model={emp} />
                    </div>
                })
                }
            </div>


            <FloatButtonAddEmployee company={company}/>
        </div>
    )
};
