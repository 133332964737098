import actionType from './homeEmployeeActionType'

import { findAllCommandsByResponsible, advancedSearch } from '../repository/commandsRepository'


export const findAllCommandsUserLogged = async dispatch => {
    const data = await findAllCommandsByResponsible();
    return {
        type: actionType.LIST_ALL,
        payload: data.sort((a, b) => {
          return (new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime());
      })
    };
  };

  export async function advancedSearchUserLogged(filter) {
    const data = await advancedSearch(filter);
    return {
      type: actionType.LIST_ALL,
      payload: data.sort((a, b) => {
        return (new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime());
    })
    };
  }
  

  export function setNewCommandInList(commands = [], data){
    let list = []
    commands.forEach(element => {
      list.push(element)
    });
    list.push(data)
    return {
        type: actionType.LIST_ALL,
        payload: list.sort((a, b) => {
          return (new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime());
      })
    };
  };


