import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAVpGQXPsV6pxbgcvr09X88f3m4CsPsgNM",
  authDomain: "salao-86511.firebaseapp.com",
  projectId: "salao-86511",
  storageBucket: "salao-86511.appspot.com",
  messagingSenderId: "96830997113",
  appId: "1:96830997113:web:dcda4801a99d90a111caa8",
  measurementId: "G-KXMBK5DWGZ"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCEKnQLwLQ_JzvPumA2aNbMQ1vGoswZ6Do",
//   authDomain: "dev-salao.firebaseapp.com",
//   projectId: "dev-salao",
//   storageBucket: "dev-salao.appspot.com",
//   messagingSenderId: "436154210339",
//   appId: "1:436154210339:web:f1a8ff8b320c619983b996"
// };

export const app = initializeApp(firebaseConfig);