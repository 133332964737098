import React from 'react'


export default props => {
    return (
        <div className="col-lg-3 col-12">
            <div className={`small-box ${props.className}`}>
                <div className="inner">
                    <h3>{props.title}</h3>
                    <p>{props.subtitle}</p>
                </div>
                <div className="icon">
                <i className={`fas ${props.icon}`}></i>
                </div>
                {/* <a href="#" className="small-box-footer">Mais informações <i className="fas fa-arrow-circle-right"></i></a> */}
            </div>
        </div>
    )
}