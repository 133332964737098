import './App.css';
import ToastMessage from './ToastMessage';
import { AuthProvider } from './contexts/auth';
import { AppRoutes } from './routes/routes';
import './template/dependencies'

function App() {
  return (
    <AuthProvider>
      <AppRoutes />
      <ToastMessage/>
    </AuthProvider>
  );
}

export default App;
