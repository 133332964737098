import { app } from "../../configs/config";
import { collection, addDoc, where, query, getDocs, updateDoc, doc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { dateInYyyyMmDd } from "../../utils/dateUtils";
import { isNull } from "../../utils/util";

export async function createCommands(data) {
    const db = getFirestore(app);
    try {
        const docRef = await addDoc(collection(db, "commands"), data);
        console.log("Document written with ID: ", docRef.g);
    } catch (e) {
        console.error("Error adding document: ", e);
        throw e;
    }
}

export async function updateCommands(data) {
    const db = getFirestore(app);
    try {
        const docRef = doc(db, "commands", data.id);
        await updateDoc(docRef, data);
        console.log("Document written with ID: ", docRef.g);
    } catch (e) {
        console.error("Error adding document: ", e);
        throw e;
    }
}

export async function findAllCommandsUserLogged() {
    const db = getFirestore(app);
    try {

        const ref = collection(db, "commands");
        const querySnapshot = await getDocs(query(ref, where("createDate", "==", dateInYyyyMmDd(new Date()))));

        let collect = []
        querySnapshot.forEach((doc) => {
            if (doc.data().status !== 'DELETED') {
                const data = {
                    ...doc.data(),
                    id: doc.id
                }
                collect.push(data)
            }
           
        });
        return collect
    } catch (e) {
        console.error("Error search document: ", e);
        return []
    }
}


export async function findAllCommandsByResponsible() {
    const db = getFirestore(app);
    try {
        const userSession = JSON.parse(localStorage.getItem("@AuthFirebase:user"));
        const ref = collection(db, "commands");
        const querySnapshot = await getDocs(query(ref,
            where("createDate", "==", dateInYyyyMmDd(new Date())),
            where("responsibleId", "==", userSession.id)));

        let collect = []
        querySnapshot.forEach((doc) => {
            if (doc.data().status !== 'DELETED') {
                const data = {
                    ...doc.data(),
                    id: doc.id
                }
                collect.push(data)
            }
           
            
        });
        return collect
    } catch (e) {
        console.error("Error search document: ", e);
        return []
    }
}

export async function advancedSearch(data) {
    const db = getFirestore(app);
    try {
        let commandsCollection = collection(db, 'commands');
        let commandsQuery = query(commandsCollection);
    
        if(!isNull(data.company)) {
            commandsQuery = query(commandsQuery,
                where('company', '==', data.company)
            );
        }

        if (!isNull(data.startDate) && !isNull(data.endDate)) {
            commandsQuery = query(commandsQuery,
                where('createDate', '>=', data.startDate),
                where('createDate', '<=', data.endDate)
            );
        } else if (!isNull(data.startDate)) {
            commandsQuery = query(commandsQuery,
                where('createDate', '>=', data.startDate)
            );
        } else if (!isNull(data.endDate)) {
            commandsQuery = query(commandsQuery,
                where('createDate', '<=', data.endDate)
            );
        }

        if (!isNull(data.status)) {
            commandsQuery = query(commandsQuery,
                where('status', '==', data.status)
            );
        }

        if (!isNull(data.professionalIds) && data.professionalIds.length > 0) {
            commandsQuery = query(commandsQuery,
                where('responsibleId', 'in', data.professionalIds)
            );
        }

        const documentosArray = await getDocs(commandsQuery)
            .then(snapshot => {
                const documentosArray = [];
                snapshot.forEach(doc => {
                    if (doc.data().status !== 'DELETED') {
                        documentosArray.push({ id: doc.id, ...doc.data() });
                   }
                });
                return documentosArray;
            })
        return documentosArray.sort((a, b) => {
            return (new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime());
        })
    } catch (e) {
        console.error("Error adding document: ", e);
        throw e;
    }
}