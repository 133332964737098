import actionType from './homeEmployeeActionType'

import { findAllCommandsUserLogged as findComands, advancedSearch} from '../repository/commandsRepository'
import { initialize } from 'redux-form';


export const findAllCommands = async dispatch => {
    const data = await findComands();
    return {
        type: actionType.LIST_ALL,
        payload: data.sort((a, b) => {
          return  b.status > a.status && (new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime());
      })
    };
  };

  
  export function updateListCommand(list) {
    return {
        type: actionType.LIST_ALL,
        payload: list.sort((a, b) => {
          return  b.status > a.status && (new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime());
      })
    };
  };


  export function setForm(data) {
    return initialize('confirmPaymentForm', data)
  };

  export async function advancedSearchCommands(filter) {
    const data = await advancedSearch(filter);
    return {
        type: actionType.LIST_ALL,
        payload: data.sort((a, b) => {
          return  b.status > a.status && (new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime());
      })
    };
  }
  



